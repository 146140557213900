import { useState, useEffect } from 'react';
import shiftService from '../services/shiftService'; // Backend service to interact with API
import {
  FaWhatsapp,
  FaExchangeAlt,
  FaFire,
  FaRedoAlt,
  FaCalendarAlt,
  FaClock,
  FaDollarSign,
} from 'react-icons/fa';

// Helper functions
const formatDate = (dateInput) => {
  let date;
  if (dateInput instanceof Date) {
    date = dateInput;
  } else if (typeof dateInput === 'string') {
    date = new Date(dateInput);
  } else if (dateInput && dateInput.$date) {
    date = new Date(dateInput.$date);
  } else {
    return 'Invalid Date';
  }
  const options = { month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

const getDayName = (dateInput) => {
  let date;
  if (dateInput instanceof Date) {
    date = dateInput;
  } else if (typeof dateInput === 'string') {
    date = new Date(dateInput);
  } else if (dateInput && dateInput.$date) {
    date = new Date(dateInput.$date);
  } else {
    return 'Invalid Date';
  }
  return date.toLocaleDateString('en-US', { weekday: 'long' });
};

const getDateString = (dateInput) => {
  let date;
  if (dateInput instanceof Date) {
    date = dateInput;
  } else if (typeof dateInput === 'string') {
    date = new Date(dateInput);
  } else if (dateInput && dateInput.$date) {
    date = new Date(dateInput.$date);
  } else {
    return 'Invalid Date';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`; // 'YYYY-MM-DD'
};

const calculateDuration = (start, end) => {
  const [startHour, startMinute] = start.split(':').map(Number);
  const [endHour, endMinute] = end.split(':').map(Number);
  const totalMinutes =
    endHour * 60 + endMinute - (startHour * 60 + startMinute);
  const totalHours = totalMinutes / 60;
  return totalHours % 1 === 0
    ? `${totalHours}h`
    : `${Math.floor(totalHours)}.5h`;
};

const isLastMinute = (shiftDateInput, shiftStart) => {
  let shiftDate;
  if (shiftDateInput instanceof Date) {
    shiftDate = shiftDateInput;
  } else if (typeof shiftDateInput === 'string') {
    shiftDate = new Date(shiftDateInput);
  } else if (shiftDateInput && shiftDateInput.$date) {
    shiftDate = new Date(shiftDateInput.$date);
  } else {
    return false;
  }
  const shiftDateString = getDateString(shiftDate);
  const shiftDateTimeString = `${shiftDateString}T${shiftStart}`;
  const shiftTime = new Date(shiftDateTimeString);
  const currentTime = new Date();
  const timeDiff = (shiftTime - currentTime) / (1000 * 60); // Difference in minutes
  return timeDiff <= 120 && timeDiff > 0;
};

const Shifts = () => {
  const [selectedShift, setSelectedShift] = useState(null); // Modal state
  const [showFilterModal, setShowFilterModal] = useState(false); // Show filter modal
  const [shifts, setShifts] = useState([]); // Original shifts data
  const [filteredShifts, setFilteredShifts] = useState([]); // Filtered shifts
  const [filters, setFilters] = useState({
    location: '',
    day: '',
    date: '',
    startTime: '',
    duration: '',
    isTrade: false,
    isLastMinute: false,
    isRecurring: false,
    hasIncentive: false,
  });
  const [error, setError] = useState(''); // Error handling

    // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch shifts data from the backend API
  const fetchShifts = async () => {
    try {
      const shiftsData = await shiftService.getShifts(); // Fetch data from backend API

      // Process shifts to handle date formats and ensure properties are correctly typed
      const processedShifts = shiftsData.map((shift) => ({
        ...shift,
        date:
          shift.date && shift.date.$date ? shift.date.$date : shift.date,
        endRecurringDate:
          shift.endRecurringDate && shift.endRecurringDate.$date
            ? shift.endRecurringDate.$date
            : shift.endRecurringDate,
        isTrade: !!shift.isTrade,
        recurring: !!shift.recurring,
        incentive: shift.incentive ? Number(shift.incentive) : 0,
        user: {
          ...shift.user,
          phone: shift.user.phone,
        },
      }));

      setShifts(processedShifts); // Set original shifts data
      setFilteredShifts(processedShifts); // Initially, filteredShifts is same as shifts
    } catch (err) {
      setError('Failed to fetch shifts. Please try again later.');
    }
  };

  useEffect(() => {
    fetchShifts(); // Fetch shifts on initial load
  }, []);

  const openModal = (shift) => setSelectedShift(shift);
  const closeModal = () => setSelectedShift(null);
  const toggleFilterModal = () => setShowFilterModal(!showFilterModal);

  // Reset filters
  const resetFilters = () => {
    setFilters({
      location: '',
      day: '',
      date: '',
      startTime: '',
      duration: '',
      isTrade: false,
      isLastMinute: false,
      isRecurring: false,
      hasIncentive: false,
    });
  };

  // Update filters based on user input
  const handleFilter = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Re-filter and sort shifts whenever filters change
  useEffect(() => {
    const filtered = shifts.filter((shift) => {
      const shiftDuration = calculateDuration(shift.start, shift.end);
      const shiftDay = getDayName(shift.date);
      const isLastMinuteShift = isLastMinute(shift.date, shift.start);
      const hasIncentive = shift.incentive > 0;

      return (
        (!filters.location ||
          shift.location
            .toLowerCase()
            .includes(filters.location.toLowerCase())) &&
        (!filters.day || shiftDay === filters.day) &&
        (!filters.date || getDateString(shift.date) === filters.date) &&
        (!filters.startTime || shift.start === filters.startTime) &&
        (!filters.duration || shiftDuration === filters.duration) &&
        (!filters.isTrade || shift.isTrade) &&
        (!filters.isLastMinute || isLastMinuteShift) &&
        (!filters.isRecurring || shift.recurring) &&
        (!filters.hasIncentive || hasIncentive)
      );
    });

    // Sort the filtered shifts by date and start time
    filtered.sort((a, b) => {
      const dateA = new Date(`${getDateString(a.date)}T${a.start}`);
      const dateB = new Date(`${getDateString(b.date)}T${b.start}`);
      return dateA - dateB;
    });

    setFilteredShifts(filtered);
  }, [filters, shifts]);

  // Ensure the time input allows only :00 or :30
  const handleTimeChange = (e) => {
    const value = e.target.value;
    const [hours, minutes] = value.split(':');
    if (minutes !== '00' && minutes !== '30') {
      e.target.value = `${hours}:${minutes < 30 ? '00' : '30'}`;
    }
    handleFilter(e);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Available Shifts</h2>

        {/* Filter button to open modal */}
        <button
          onClick={toggleFilterModal}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
        >
          Filter Shifts
        </button>
      </div>

      {/* Error message */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Legend */}
      <div className="flex justify-center space-x-6 mb-4">
        <div className="flex items-center space-x-2">
          <FaExchangeAlt className="text-blue-500" />
          <span>Trade</span>
        </div>
        <div className="flex items-center space-x-2">
          <FaFire className="text-red-500" />
          <span>Soon</span> {/* Changed from Last-Minute to Soon */}
        </div>
        <div className="flex items-center space-x-2">
          <FaRedoAlt className="text-green-500" />
          <span>Recurring</span>
        </div>
        <div className="flex items-center space-x-2">
          <FaDollarSign className="text-green-500" />
          <span>Incentive</span>
        </div>
      </div>

      {/* Filter Modal */}
      {showFilterModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h3 className="text-xl font-bold mb-4 text-center">
              Filter Shifts
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* Date Filter */}
              <div className="relative">
                <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="date"
                  name="date"
                  value={filters.date}
                  className="w-full px-10 py-2 rounded-lg border border-gray-300"
                  onChange={handleFilter}
                />
              </div>

              {/* Day Filter */}
              <div className="relative">
                <select
                  name="day"
                  value={filters.day}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300"
                  onChange={handleFilter}
                >
                  <option value="">Day</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </select>
              </div>

              {/* Start Time */}
              <div className="relative">
                <FaClock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="time"
                  name="startTime"
                  value={filters.startTime}
                  className="w-full px-10 py-2 rounded-lg border border-gray-300"
                  step="1800" // restricts minutes to 00 or 30
                  onChange={handleTimeChange}
                />
              </div>

              {/* Duration */}
              <div className="relative">
                <select
                  name="duration"
                  value={filters.duration}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300"
                  onChange={handleFilter}
                >
                  <option value="">Duration</option>
                  <option value="4h">4h</option>
                  <option value="8h">8h</option>
                  <option value="10h">10h</option>
                  <option value="12h">12h</option>
                  <option value="14h">14h</option>
                  <option value="16h">16h</option>
                </select>
              </div>

              {/* Checkboxes */}
              <div className="flex justify-between items-center col-span-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="isTrade"
                    checked={filters.isTrade}
                    onChange={handleFilter}
                    className="form-checkbox"
                  />
                  <span>Trade Shift</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="isLastMinute"
                    checked={filters.isLastMinute}
                    onChange={handleFilter}
                    className="form-checkbox"
                  />
                  <span>Soon</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="isRecurring"
                    checked={filters.isRecurring}
                    onChange={handleFilter}
                    className="form-checkbox"
                  />
                  <span>Recurring</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    name="hasIncentive"
                    checked={filters.hasIncentive}
                    onChange={handleFilter}
                    className="form-checkbox"
                  />
                  <span>Incentive</span>
                </label>
              </div>
            </div>

            {/* Reset Filters Button */}
            <div className="flex justify-between mt-4">
              <button
                onClick={resetFilters}
                className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
              >
                Reset Filters
              </button>
              <button
                onClick={toggleFilterModal}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400"
              >
                Close Filter
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Shifts List */}
      <div className="space-y-4">
        {filteredShifts.map((shift, index) => {
          const isLastMinuteShift = isLastMinute(shift.date, shift.start);
          return (
            <div
              key={index}
              className="bg-white p-4 rounded-lg shadow-md flex flex-col cursor-pointer"
              onClick={() => openModal(shift)}
            >
              {/* Container with three columns */}
              <div className="flex items-center">
                {/* Left Column */}
                <div className="flex flex-col flex-shrink-0 w-1/4">
                  <p className="font-bold">{shift.location}</p>
                  <p className="text-sm text-gray-500">{shift.checkpoint}</p>
                </div>

                {/* Center Column */}
                <div className="flex flex-col items-center flex-grow">
                  {/* Icons */}
                  <div className="flex items-center space-x-2">
                    {shift.isTrade && <FaExchangeAlt className="text-blue-500" />}
                    {isLastMinuteShift && <FaFire className="text-red-500" />}
                    {shift.recurring && <FaRedoAlt className="text-green-500" />}
                    {Number(shift.incentive) > 0 && (
                      <FaDollarSign className="text-green-500" />
                    )}
                  </div>
                  {/* Start and End Time */}
                  <p className="text-sm text-gray-500 text-center">
                    {`${shift.start} - ${shift.end}`}
                  </p>
                </div>

                {/* Right Column */}
                <div className="flex flex-col items-end flex-shrink-0 w-1/4">
                  <p className="text-sm">{formatDate(shift.date)}</p>
                  <p className="text-sm text-gray-500">{getDayName(shift.date)}</p>
                </div>
              </div>
            </div>
          );
        })}

      </div>

      {/* Modal for Shift Details */}
      {selectedShift && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            {/* Modal Header: Location - Checkpoint */}
            <h3 className="text-xl font-bold mb-4 text-center">
              {selectedShift.location} - {selectedShift.checkpoint}
            </h3>

            {/* Day - Date */}
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm">
                <strong>Day:</strong> {getDayName(selectedShift.date)}
              </p>
              <p className="text-sm">
                <strong>Date:</strong> {formatDate(selectedShift.date)}
              </p>
            </div>

            {/* Start - End */}
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm">
                <strong>Start:</strong> {selectedShift.start}
              </p>
              <p className="text-sm">
                <strong>End:</strong> {selectedShift.end}
              </p>
              {Number(selectedShift.incentive) <= 0 && (
                <p className="text-sm">
                  <strong>Duration:</strong>{' '}
                  {calculateDuration(
                    selectedShift.start,
                    selectedShift.end
                  )}
                </p>
              )}
            </div>

            {Number(selectedShift.incentive) > 0 && (
              <div className="flex justify-between items-center mb-2">
                <p className="text-sm">
                  <strong>Duration:</strong>{' '}
                  {calculateDuration(
                    selectedShift.start,
                    selectedShift.end
                  )}
                </p>
                <p className="text-sm">
                  <strong>Incentive:</strong> $
                  {selectedShift.incentive}
                </p>
              </div>
            )}

            {selectedShift.recurring &&
              selectedShift.endRecurringDate && (
                <p className="text-sm mb-4">
                  <strong>Recurring:</strong> Every{' '}
                  {getDayName(selectedShift.date)} from{' '}
                  {formatDate(selectedShift.date)} to{' '}
                  {formatDate(selectedShift.endRecurringDate)}
                </p>
              )}

            {selectedShift.note && (
              <p className="text-sm mb-4">
                <strong>Note:</strong> {selectedShift.note}
              </p>
            )}

            {/* WhatsApp Contact */}
            <a
              href={`https://wa.me/${selectedShift.user.phone}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
            >
              <FaWhatsapp className="mr-2" />
              Contact via WhatsApp
            </a>
            <button
              onClick={closeModal}
              className="mt-4 w-full bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Shifts;
