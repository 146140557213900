// src/services/authService.js
import axios from 'axios';

const API_URL = 'http://gardashifts.com/api/auth'; // Backend URL

// Register a new user
const register = async (userData) => {
  const response = await axios.post(`${API_URL}/register`, userData);
  return response.data;
};

// Login a user
const login = async (userData) => {
  const response = await axios.post(`${API_URL}/login`, userData);

  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
  }

  return response.data;
};

// Logout the user (remove token)
const logout = () => {
  localStorage.removeItem('token');
};

// Get token from localStorage
const getToken = () => {
  return localStorage.getItem('token');
};

// Forgot Password
const forgotPassword = async (emailData) => {
  const response = await axios.post(
    'http://gardashifts.com/api/auth/forgot-password',
    emailData
  );
  return response.data;
};

// Reset Password
const resetPassword = async (passwordData) => {
  const response = await axios.post(
    'http://gardashifts.com/api/auth/reset-password',
    passwordData
  );
  return response.data;
};

export default {
  register,
  login,
  logout,
  getToken,
  forgotPassword,
  resetPassword,
};
