// src/services/shiftService.js
import axios from 'axios';
import authService from './authService'; // Assuming you still need authService for other functions

const API_URL = 'http://gardashifts.com/api/shifts';

// Set Authorization header with token
const setAuthHeader = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found, please log in.');
  }

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
};

// Fetch all shifts
const getShifts = async (filters = {}) => {
  const response = await axios.get(API_URL, { params: filters });
  return response.data;
};

// Fetch logged-in user's shifts
const getUserShifts = async () => {
  try {
    const response = await axios.get(`${API_URL}/user`, setAuthHeader());
    return response.data;
  } catch (err) {
    console.error('Error fetching user shifts', err.response ? err.response.data : err.message);
    throw err;
  }
};

// Post a new shift
const postShift = async (shiftData) => {
  try {
    console.log("Sending shift data to backend:", shiftData); // Add logging for debugging
    const response = await axios.post(API_URL, shiftData, setAuthHeader());
    console.log("Response from backend:", response.data); // Log backend response for debugging
    return response.data;
  } catch (err) {
    console.error("Error posting shift", err.response ? err.response.data : err.message);
    throw err;
  }
};

// Delete a shift
const deleteShift = async (shiftId) => {
  try {
    const response = await axios.delete(`${API_URL}/${shiftId}`, setAuthHeader());
    console.log('Shift deleted successfully:', response.data); // Log the response from the backend
    return response.data;
  } catch (err) {
    console.error("Error deleting shift:", err.response ? err.response.data : err.message); // Log detailed error
    throw err; // Throw error to catch it in MyShifts.js
  }
};


export default {
  getShifts,
  getUserShifts,
  postShift,
  deleteShift,
};
