import { useState } from 'react';
import { FaTrashAlt, FaCalendarAlt, FaClock } from 'react-icons/fa';

// Dummy user data for demonstration (replace with actual data from backend)
const userData = {
  username: 'JohnDoe',
  email: 'johndoe@example.com',
  phone: '+1234567890',
  notifications: [],
};

const locations = ['T1', 'T3', 'Island', 'Hamilton'];
const checkpoints = ['International', 'Domestic', 'TB', 'ITD/ITPC', 'NPS', 'NPS-V', 'HBS', 'RSR'];

const Settings = () => {
  const [user, setUser] = useState(userData); // User state for profile and settings
  const [newNotification, setNewNotification] = useState({
    date: '',
    day: '',
    startTime: '',
    location: '',
    checkpoint: '',
  }); // State for new shift notification
  const [profileUpdated, setProfileUpdated] = useState(false); // State to indicate if profile was updated

  // Handle user profile updates
  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
    setProfileUpdated(true);
  };

  // Save profile changes
  const updateProfile = () => {
    alert('Profile updated successfully!');
    setProfileUpdated(false); // Reset update indicator after saving
  };

  // Handle new notification setup
  const handleNotificationChange = (e) => {
    const { name, value } = e.target;
    setNewNotification((prevNotification) => ({ ...prevNotification, [name]: value }));
  };

  // Save new notification (limit to 3 notifications)
  const addNotification = () => {
    if (user.notifications.length < 3) {
      setUser((prevUser) => ({
        ...prevUser,
        notifications: [...prevUser.notifications, newNotification],
      }));
      setNewNotification({ date: '', day: '', startTime: '', location: '', checkpoint: '' });
    } else {
      alert('You can only set up to 3 notifications.');
    }
  };

  // Delete a notification
  const deleteNotification = (index) => {
    setUser((prevUser) => ({
      ...prevUser,
      notifications: prevUser.notifications.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Settings</h2>

      {/* Profile Settings */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Profile</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <input
            type="text"
            name="username"
            value={user.username}
            onChange={handleProfileChange}
            placeholder="Username"
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          />
          <input
            type="email"
            name="email"
            value={user.email}
            onChange={handleProfileChange}
            placeholder="Email"
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          />
          <input
            type="tel"
            name="phone"
            value={user.phone}
            onChange={handleProfileChange}
            placeholder="Phone Number (must start with +1)"
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          />
        </div>

        {/* Update Profile Button */}
        {profileUpdated && (
          <button
            onClick={updateProfile}
            className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
          >
            Update Profile
          </button>
        )}
      </div>

      {/* Notification Setup */}
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Notifications</h3>
        <p className="text-sm mb-2">Get notified when a shift matching the following criteria is posted:</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="relative">
            <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="date"
              name="date"
              value={newNotification.date}
              onChange={handleNotificationChange}
              className="w-full pl-10 py-2 rounded-lg border border-gray-300"
              placeholder="Date"
            />
          </div>

          <select
            name="day"
            value={newNotification.day}
            onChange={handleNotificationChange}
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          >
            <option value="">Day</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>

          <div className="relative">
            <FaClock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="time"
              name="startTime"
              value={newNotification.startTime}
              onChange={handleNotificationChange}
              className="w-full pl-10 py-2 rounded-lg border border-gray-300"
              placeholder="Start Time"
            />
          </div>

          <select
            name="location"
            value={newNotification.location}
            onChange={handleNotificationChange}
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          >
            <option value="">Location</option>
            {locations.map((loc) => (
              <option key={loc} value={loc}>
                {loc}
              </option>
            ))}
          </select>
          <select
            name="checkpoint"
            value={newNotification.checkpoint}
            onChange={handleNotificationChange}
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
          >
            <option value="">Checkpoint</option>
            {checkpoints.map((cp) => (
              <option key={cp} value={cp}>
                {cp}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={addNotification}
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
        >
          Add Notification
        </button>

        {/* Display existing notifications */}
        <div className="mt-6">
          <h4 className="font-semibold mb-2">Active Notifications</h4>
          {user.notifications.length > 0 ? (
            <ul>
              {user.notifications.map((notif, index) => (
                <li key={index} className="mb-2 flex justify-between items-center">
                  <div>
                    {notif.date && <strong>Date:</strong>} {notif.date && notif.date}, {' '}
                    {notif.day && <strong>Day:</strong>} {notif.day && notif.day}, {' '}
                    {notif.startTime && <strong>Start Time:</strong>} {notif.startTime && notif.startTime}, {' '}
                    {notif.location && <strong>Location:</strong>} {notif.location && notif.location}, {' '}
                    {notif.checkpoint && <strong>Checkpoint:</strong>} {notif.checkpoint && notif.checkpoint}
                  </div>
                  <button
                    onClick={() => deleteNotification(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrashAlt size={20} />
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No active notifications</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
