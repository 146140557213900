import { useState, useEffect } from 'react';
import { FaTrashAlt, FaPlusCircle } from 'react-icons/fa';
import moment from 'moment-timezone'; // Import moment-timezone for date/time handling
import shiftService from '../services/shiftService'; // Service to handle API calls

// Helper to format date and get day of the week using moment-timezone
const formatDate = (dateString) => {
  const timezone = 'America/New_York'; // Set the desired time zone
  return moment.tz(dateString, timezone).format('MMM D');
};

const getDayOfWeek = (dateString) => {
  const timezone = 'America/New_York'; // Set the desired time zone
  return moment.tz(dateString, timezone).format('dddd');
};

const MyShifts = () => {
  const [shifts, setShifts] = useState([]); // State for user shifts
  const [isPosting, setIsPosting] = useState(false); // Toggle for posting a new shift
  const [shiftToDelete, setShiftToDelete] = useState(null); // Shift to delete
  const [error, setError] = useState(''); // Error handling state

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch the user's shifts from the backend
  const fetchShifts = async () => {
    try {
      const userShifts = await shiftService.getUserShifts();
      setShifts(userShifts);
    } catch (err) {
      setError('Failed to load shifts. Please try again later.');
    }
  };

  useEffect(() => {
    fetchShifts(); // Fetch shifts on component load
  }, []);

  // Handle posting a shift
  const handlePostShift = async (newShift) => {
    try {
      const timezone = 'America/New_York'; // Set the desired time zone
      const shiftDate = moment.tz(`${newShift.date} ${newShift.start}`, 'YYYY-MM-DD HH:mm', timezone);

      // Prepare the shift object to send
      const shiftToSend = {
        location: newShift.location,
        checkpoint: newShift.checkpoint,
        start: newShift.start,
        end: newShift.end,
        date: shiftDate.toISOString(), // Use moment-timezone to format date in ISO
        incentive: newShift.incentive ? parseInt(newShift.incentive, 10) : 0, // Ensure the incentive is an integer
        note: newShift.note || '', // Set empty string if note is not provided
        recurring: newShift.recurring || false,
        isTrade: newShift.isTrade || false,
      };

      // Only include endRecurringDate if it's provided and recurring is true
      if (newShift.recurring && newShift.endRecurringDate) {
        shiftToSend.endRecurringDate = moment
          .tz(newShift.endRecurringDate, 'YYYY-MM-DD', timezone)
          .toISOString();
      }

      // Add new shift
      const createdShift = await shiftService.postShift(shiftToSend);
      setShifts([...shifts, createdShift]);

      setIsPosting(false); // Close form
    } catch (err) {
      setError('Failed to save shift. Please try again.');
    }
  };

  // Delete a shift
  const handleDeleteShift = async (shiftId) => {
    try {
      await shiftService.deleteShift(shiftId);
      setShifts(shifts.filter((shift) => shift._id !== shiftId));
      setShiftToDelete(null);
    } catch (err) {
      setError('Failed to delete shift. Please try again.');
    }
  };

  // Toggle form for posting a shift
  const openPostShiftForm = () => {
    setIsPosting(true);
  };

  // Open delete confirmation modal
  const confirmDeleteShift = (shift) => {
    setShiftToDelete(shift);
  };

  return (
    <div className="p-4">
      {error && <p className="text-red-500">{error}</p>}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">My Shifts</h2>
        <button
          onClick={openPostShiftForm}
          className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 flex items-center"
        >
          <FaPlusCircle className="mr-2" />
          Post Shift
        </button>
      </div>

      {/* List of user's posted shifts */}
      <div className="space-y-4">
        {shifts.length === 0 ? (
          <p className="text-center text-gray-500">You have no posted shifts.</p>
        ) : (
          shifts.map((shift) => (
            <div
              key={shift._id}
              className="bg-white p-4 rounded-lg shadow-md flex justify-between items-center"
            >
              {/* Left part with shift details */}
              <div>
                <p className="font-bold">
                  {shift.location} - {shift.checkpoint}
                </p>
                <p className="text-sm text-gray-500">
                  {shift.start} - {shift.end}, {getDayOfWeek(shift.date)},{' '}
                  {formatDate(shift.date)}
                </p>
              </div>

              {/* Right part with delete icon */}
              <div className="flex space-x-4">
                <button
                  onClick={() => confirmDeleteShift(shift)}
                  className="text-red-500 hover:text-red-700"
                >
                  <FaTrashAlt size={20} />
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Post Shift Form */}
      {isPosting && (
        <PostShiftForm
          onSubmit={handlePostShift}
          onCancel={() => setIsPosting(false)}
        />
      )}

      {/* Delete Confirmation Modal */}
      {shiftToDelete && (
        <ConfirmationModal
          shift={shiftToDelete}
          onConfirm={() => handleDeleteShift(shiftToDelete._id)}
          onCancel={() => setShiftToDelete(null)}
        />
      )}
    </div>
  );
};

// Form component for posting a shift
const PostShiftForm = ({ onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    location: '',
    checkpoint: '',
    startHour: '',
    startMinute: '',
    endHour: '',
    endMinute: '',
    date: '',
    incentive: '',
    note: '',
    recurring: false,
    endRecurringDate: '',
    isTrade: false,
  });

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Combine hours and minutes to create start and end times
    const start = `${formData.startHour}:${formData.startMinute}`;
    const end = `${formData.endHour}:${formData.endMinute}`;

    // Validate that start and end times are in correct format
    if (!formData.startHour || !formData.startMinute || !formData.endHour || !formData.endMinute) {
      alert('Please select valid start and end times.');
      return;
    }

    // Ensure that the start time is before the end time
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    if (!endTime.isAfter(startTime)) {
      alert('End time must be after start time.');
      return;
    }

    // Pass formData to the onSubmit function in MyShifts
    onSubmit({
      ...formData,
      start,
      end,
    });
  };

  // Generate options for hours and minutes
  const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
  const minutes = ['00', '30'];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold mb-4">Post Shift</h3>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* Location dropdown */}
            <div className="col-span-2">
              <label className="block text-gray-700 mb-1">Location</label>
              <select
                name="location"
                value={formData.location}
                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                className="w-full px-4 py-2 rounded-lg border border-gray-300"
                required
              >
                <option value="">Select Location</option>
                <option value="T1">T1</option>
                <option value="T3">T3</option>
                <option value="Island">Island</option>
                <option value="Hamilton">Hamilton</option>
              </select>
            </div>

            {/* Checkpoint dropdown */}
            <div className="col-span-2">
              <label className="block text-gray-700 mb-1">Checkpoint</label>
              <select
                name="checkpoint"
                value={formData.checkpoint}
                onChange={(e) => setFormData({ ...formData, checkpoint: e.target.value })}
                className="w-full px-4 py-2 rounded-lg border border-gray-300"
                required
              >
                <option value="">Select Checkpoint</option>
                <option value="International">International</option>
                <option value="Domestic">Domestic</option>
                <option value="TB">TB</option>
                <option value="ITD/ITPC">ITD/ITPC</option>
                <option value="NPS">NPS</option>
                <option value="NPS-V">NPS-V</option>
                <option value="HBS">HBS</option>
                <option value="RSR">RSR</option>
              </select>
            </div>

            {/* Start Time */}
            <div className="col-span-1">
              <label className="block text-gray-700 mb-1">Start Time</label>
              <div className="flex space-x-2">
                <select
                  name="startHour"
                  value={formData.startHour}
                  onChange={(e) => setFormData({ ...formData, startHour: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300"
                  required
                >
                  <option value="">HH</option>
                  {hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>
                <select
                  name="startMinute"
                  value={formData.startMinute}
                  onChange={(e) => setFormData({ ...formData, startMinute: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300"
                  required
                >
                  <option value="">MM</option>
                  {minutes.map((minute) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* End Time */}
            <div className="col-span-1">
              <label className="block text-gray-700 mb-1">End Time</label>
              <div className="flex space-x-2">
                <select
                  name="endHour"
                  value={formData.endHour}
                  onChange={(e) => setFormData({ ...formData, endHour: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300"
                  required
                >
                  <option value="">HH</option>
                  {hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>
                <select
                  name="endMinute"
                  value={formData.endMinute}
                  onChange={(e) => setFormData({ ...formData, endMinute: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300"
                  required
                >
                  <option value="">MM</option>
                  {minutes.map((minute) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Date */}
            <div className="col-span-2">
              <label className="block text-gray-700 mb-1">Date</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                className="w-full px-4 py-2 rounded-lg border border-gray-300"
                required
              />
            </div>

            {/* Incentive (optional) */}
            <div className="col-span-2">
              <label className="block text-gray-700 mb-1">Incentive (optional)</label>
              <input
                type="number"
                name="incentive"
                placeholder="Incentive amount"
                value={formData.incentive}
                min={0}
                max={999}
                onChange={(e) => setFormData({ ...formData, incentive: e.target.value })}
                className="w-full px-4 py-2 rounded-lg border border-gray-300"
              />
            </div>

            {/* Note (optional) */}
            <div className="col-span-2">
              <label className="block text-gray-700 mb-1">Note (max 140 characters)</label>
              <textarea
                name="note"
                placeholder="Additional information"
                value={formData.note}
                maxLength={140}
                onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                className="w-full px-4 py-2 rounded-lg border border-gray-300"
              />
            </div>

            {/* Recurring Shift */}
            <div className="col-span-2">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="recurring"
                  checked={formData.recurring}
                  onChange={(e) => setFormData({ ...formData, recurring: e.target.checked })}
                  className="form-checkbox"
                />
                <span>Recurring Shift</span>
              </label>
            </div>

            {/* End Date for Recurring Shifts */}
            {formData.recurring && (
              <div className="col-span-2">
                <label className="block text-gray-700 mb-1">End Date for Recurring Shift</label>
                <input
                  type="date"
                  name="endRecurringDate"
                  value={formData.endRecurringDate}
                  onChange={(e) => setFormData({ ...formData, endRecurringDate: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300"
                  required
                />
              </div>
            )}

            {/* Trade Shift Checkbox */}
            <div className="col-span-2">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="isTrade"
                  checked={formData.isTrade}
                  onChange={(e) => setFormData({ ...formData, isTrade: e.target.checked })}
                  className="form-checkbox"
                />
                <span>Trade Shift</span>
              </label>
            </div>
          </div>

          <div className="flex justify-between mt-4">
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
            >
              Post Shift
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Confirmation modal for deleting a shift
const ConfirmationModal = ({ shift, onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h3 className="text-xl font-bold mb-4 text-center">
          {shift.location} - {shift.checkpoint}
        </h3>
        <p className="text-sm text-center mb-4">
          {getDayOfWeek(shift.date)}, {formatDate(shift.date)} <br />
          {shift.start} - {shift.end}
        </p>
        <p className="text-sm text-center mb-6">
          Are you sure you want to delete this shift?
        </p>

        <div className="flex justify-between">
          <button
            onClick={() => onConfirm(shift._id)} // Ensure the correct ID is passed
            className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
          >
            Delete
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyShifts;
