import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaCalendarCheck, FaCog, FaSignOutAlt, FaNewspaper } from 'react-icons/fa';

const BottomNav = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear user authentication tokens or any relevant state here
    onLogout(); // Call the logout handler passed from App.js
    navigate('/login'); // Redirect to the login page after logout
  };

  return (
    <nav className="fixed bottom-0 w-full bg-white shadow-lg border-t-2 border-gray-200 h-16">
      <ul className="flex justify-around p-4">
        <li>
          <Link to="/shifts" className="flex flex-col items-center text-gray-700 hover:text-blue-500">
            <FaHome className="text-2xl" />
            <span className="text-sm">Shifts</span>
          </Link>
        </li>
        <li>
          <Link to="/my-shifts" className="flex flex-col items-center text-gray-700 hover:text-blue-500">
            <FaCalendarCheck className="text-2xl" />
            <span className="text-sm">My Shifts</span>
          </Link>
        </li>
        {/* Add News Button */}
        <li>
          <Link to="/news" className="flex flex-col items-center text-gray-700 hover:text-blue-500">
            <FaNewspaper className="text-2xl" />
            <span className="text-sm">News</span>
          </Link>
        </li>
        <li>
          <Link to="/settings" className="flex flex-col items-center text-gray-700 hover:text-blue-500">
            <FaCog className="text-2xl" />
            <span className="text-sm">Settings</span>
          </Link>
        </li>
        <li>
          <button onClick={handleLogout} className="flex flex-col items-center text-gray-700 hover:text-red-500">
            <FaSignOutAlt className="text-2xl" />
            <span className="text-sm">Logout</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default BottomNav;
