import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState } from 'react';
import Shifts from './components/Shifts';
import MyShifts from './components/MyShifts';
import Settings from './components/Settings';
import Login from './components/Login';
import Register from './components/Register';
import BottomNav from './components/BottomNav';
import News from './components/News';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Manage logged-in state

  const handleLogin = () => {
    setIsLoggedIn(true); // This will mark the user as logged in
  };

  const handleLogout = () => {
    setIsLoggedIn(false); // Reset logged-in state
  };

  // Private route wrapper
  const PrivateRoute = ({ element }) => {
    return isLoggedIn ? element : <Navigate to="/login" />;
  };

  return (
    <Router>
      <div className="min-h-screen">
        <Routes>
          <Route path="/login" element={<Login onLogin={handleLogin} />} /> {/* Pass handleLogin */}
          <Route path="/register" element={<Register />} />
          <Route path="/shifts" element={<PrivateRoute element={<Shifts />} />} />
          <Route path="/my-shifts" element={<PrivateRoute element={<MyShifts />} />} />
          <Route path="/news" element={<PrivateRoute element={<News />} />} /> {/* Add News route */}
          <Route path="/settings" element={<PrivateRoute element={<Settings />} />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>

        {/* Render BottomNav only when the user is logged in */}
        {isLoggedIn && <BottomNav onLogout={handleLogout} />}
      </div>
    </Router>
  );
};

export default App;
