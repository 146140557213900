// src/components/Register.js
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import authService from '../services/authService';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('+1'); // Predefine +1 for phone number
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors
    setSuccess(''); // Clear success messages
    setLoading(true); // Show loading state

    try {
      // Call the registration service
      const response = await authService.register({ username, email, password, phone });

      // Show success message
      setSuccess('Registration successful! Redirecting to login...');
      
      // Delay navigation to login for better UX
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      console.error('Error during registration:', err);

      // Check if there's a response from the server
      if (err.response && err.response.data) {
        setError(err.response.data.msg || 'Error creating account');
      } else {
        setError('Error creating account. Please try again.');
      }
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        {/* Logo */}
        <div className="text-center mb-4">
          <img src="/gardashifts.png" alt="Garda Shifts Logo" className="mx-auto w-48 h-48" />
        </div>

        {/* Register heading */}
        <h2 className="text-2xl font-bold mb-6 text-center">Register</h2>

        {/* Error Notification */}
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded mb-4 text-center">
            {error}
          </div>
        )}

        {/* Success Notification */}
        {success && (
          <div className="bg-green-100 text-green-700 p-4 rounded mb-4 text-center">
            {success}
          </div>
        )}

        <form onSubmit={handleRegisterSubmit} className="space-y-4">
          {/* Username input */}
          <input
            type="text"
            placeholder="Username (max 10 chars, alphanumeric)"
            value={username}
            onChange={(e) => {
              const input = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Allow only alphanumeric characters
              setUsername(input.substring(0, 10)); // Limit to 10 characters
            }}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
            required
          />

          {/* Email input */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" // Email validation pattern
            title="Please enter a valid email address (e.g., user@example.com)"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
            required
          />

          {/* Password input */}
          <input
            type="password"
            placeholder="Password (6-15 chars, alphanumeric)"
            value={password}
            onChange={(e) => {
              const input = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Allow only alphanumeric characters
              setPassword(input.substring(0, 15)); // Limit to 15 characters
            }}
            minLength="6" // Minimum password length
            title="Password must be between 6 and 15 characters long and only contain letters and numbers"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
            required
          />

          {/* Phone number input */}
          <input
            type="tel"
            placeholder="Phone Number (e.g., +1XXXXXXXXXX)"
            value={phone}
            onChange={(e) => {
              const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
              setPhone('+1' + input.slice(1, 11)); // Limit to 10 digits after +1
            }}
            pattern="\+1\d{10}" // Ensure the phone number matches +1XXXXXXXXXX
            title="Phone number must be in the format +1XXXXXXXXXX"
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
            required
          />

          <button
            type="submit"
            className={`w-full py-2 rounded-lg ${loading ? 'bg-gray-500' : 'bg-green-500 hover:bg-green-600'} text-white`}
            disabled={loading}
          >
            {loading ? (
              <span className="flex justify-center items-center">
                <svg className="animate-spin h-5 w-5 mr-3 border-t-2 border-white rounded-full" viewBox="0 0 24 24"></svg>
                Registering...
              </span>
            ) : (
              'Register'
            )}
          </button>
        </form>

        <div className="mt-6 text-center">
          <p className="text-sm">
            Already have an account?{' '}
            <Link to="/login" className="text-blue-500 hover:underline">
              Login here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
